<template>
  <div class="Xuexiao">
    <div class="Guize pd40">
      <div class="container">
        <div class="headTit">
          <h3><span>ZHUXUE</span> Application Service</h3>
        </div>
        <p class="_txt">By using the ZHUXUE application service, you can make multiple applications at one time. ZHUXUE consultants will help you to choose the best programs, and the ZHUXUE Student Service Team will assist you to prepare and submit the required documents.</p>
        <p class="_txt">Once Chinese universities have received your applications, ZHUXUE will regularly report to you about which stage of processing your application is up to. ZHUXUE is responsible for getting admission information from universities and informing students of the admission decision online. ZHUXUE is commissioned to receive your admission package from universities and deliver it to you.</p>
        <div class="imgbox">
          <img src="@/assets/shenqing1.png"
               alt="">
        </div>
        <p class="_tit">Tips</p>
        <p class="_titp">Generally, you only need to pay a minimum ZHUXUE service fee of $50. The following 9 universities charge $150 instead:</p>
        <div class="list">
          <div class="lis">
            <div class="imgbox">
              <img src="@/assets/xiaohui1.jpg"
                   alt="">
            </div>
            <p>Peking University</p>
          </div>
          <div class="lis">
            <div class="imgbox">
              <img src="@/assets/xiaohui2.jpg"
                   alt="">
            </div>
            <p>Fudan University</p>
          </div>
          <div class="lis">
            <div class="imgbox">
              <img src="@/assets/xiaohui3.jpg"
                   alt="">
            </div>
            <p>Tsinghua University</p>
          </div>
          <div class="lis">
            <div class="imgbox">
              <img src="@/assets/xiaohui4.jpg"
                   alt="">
            </div>
            <p>Tongji University</p>
          </div>
          <div class="lis">
            <div class="imgbox">
              <img src="@/assets/xiaohui5.jpg"
                   alt="">
            </div>
            <p>Sun Yat-sen University</p>
          </div>
          <div class="lis">
            <div class="imgbox">
              <img src="@/assets/xiaohui6.jpg"
                   alt="">
            </div>
            <p>Jinan University</p>
          </div>
          <div class="lis">
            <div class="imgbox">
              <img src="@/assets/xiaohui7.jpg"
                   alt="">
            </div>
            <p>Renmin University of China</p>
          </div>
          <div class="lis">
            <div class="imgbox">
              <img src="@/assets/xiaohui8.jpg"
                   alt="">
            </div>
            <p>Nanjing University of Aeronautics and Astronautics</p>
          </div>
          <div class="lis">
            <div class="imgbox">
              <img src="@/assets/xiaohui9.jpg"
                   alt="">
            </div>
            <p>Minzu University of China</p>
          </div>

        </div>
        <div class="bzt">
          Application Procedure
        </div>
        <div class="imgbox"
             style="margin-bottom:20px;">
          <img src="@/assets/shenqing2.jpg"
               alt="">
        </div>
        <div class="buzhou">
          <el-timeline>
            <el-timeline-item timestamp="STEP 1"
                              placement="top">
              <el-card>
                <h4>Choose university & course</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="STEP 2"
                              placement="top">
              <el-card>
                <h4>Complete online application</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="STEP 3"
                              placement="top">
              <el-card>
                <h4>Pay the application fee which is directly charged by the university</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="STEP 4"
                              placement="top">
              <el-card>
                <h4>Track application status and get the result</h4>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
        <div class="oth">
          <p>For enquiries, please click the link</p>
          <div class="_globalBtn"
               @click="dialogVisible = true">Book your place</div>
        </div>
        <div class="tit">
          After Application You Might Need To Know
        </div>
        <p>Application is successful. What should I do?</p>
        <p>Application is rejected. What should I do?</p>
        <div class="tit">
          Service Fee
        </div>
        <p>ZHUXUE charges a minimum service fee of $50.</p>
        <p>You need to pay the application fee which is directly charged by the university, and ZHUXUE is authorized to collect on their behalf.</p>
        <div class="tit">
          University Application Service Standard
        </div>
        <p>Answer all calls within 60 seconds</p>
        <p>Process applications within 24 hours of submission to ZHUXUE</p>
        <p>Release admission decision online once receiving the decision from the university</p>
        <p>Respond to emails within one working day</p>
        <p>Deliver Admission Package by DHL (free for the first time), once the mailing address is confirmed</p>
        <div class="tit">
          FAQ
        </div>
        <div class="_rowNav">
          <p :class="navRow == 0 ? 'on':''"
             @click="navRow = 0">Choosing Courses</p>
          <p :class="navRow == 1 ? 'on':''"
             @click="navRow = 1">Apply Online</p>
          <p :class="navRow == 2 ? 'on':''"
             @click="navRow = 2">Paying Fees</p>
          <p :class="navRow == 3 ? 'on':''"
             @click="navRow = 3">Getting Results</p>
        </div>
        <div class="faq"
             :class="navRow == 0 ? 'on':''">
          <el-collapse accordion>
            <el-collapse-item v-for="(item,index) in faq"
                              :key="index"
                              :disabled="true">
              <template #title>
                <h3 class="faqtit">
                  <div class="imgbox"><img src="@/assets/qu.png"
                         alt=""></div> {{item.ft}}
                </h3>
              </template>
              <div class="asCont">
                <div class="imgbox">
                  <img src="@/assets/as.png"
                       alt="">
                </div>
                <div class="txt">
                  {{item.fa}}
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="faq"
             :class="navRow == 1 ? 'on':''">
          <el-collapse accordion>
            <el-collapse-item v-for="(item,index) in faq2"
                              :key="index"
                              :disabled="true">
              <template #title>
                <h3 class="faqtit">
                  <div class="imgbox"><img src="@/assets/qu.png"
                         alt=""></div> {{item.ft}}
                </h3>
              </template>
              <div class="asCont">
                <div class="imgbox">
                  <img src="@/assets/as.png"
                       alt="">
                </div>
                <div class="txt">
                  {{item.fa}}
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="faq"
             :class="navRow == 2 ? 'on':''">
          <el-collapse accordion>
            <el-collapse-item v-for="(item,index) in faq3"
                              :key="index"
                              :disabled="true">
              <template #title>
                <h3 class="faqtit">
                  <div class="imgbox"><img src="@/assets/qu.png"
                         alt=""></div> {{item.ft}}
                </h3>
              </template>
              <div class="asCont">
                <div class="imgbox">
                  <img src="@/assets/as.png"
                       alt="">
                </div>
                <div class="txt">
                  {{item.fa}}
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="faq"
             :class="navRow == 3 ? 'on':''">
          <el-collapse accordion>
            <el-collapse-item v-for="(item,index) in faq4"
                              :key="index"
                              :disabled="true">
              <template #title>
                <h3 class="faqtit">
                  <div class="imgbox"><img src="@/assets/qu.png"
                         alt=""></div> {{item.ft}}
                </h3>
              </template>
              <div class="asCont">
                <div class="imgbox">
                  <img src="@/assets/as.png"
                       alt="">
                </div>
                <div class="txt">
                  {{item.fa}}
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>

    <el-dialog v-model="dialogVisible"
               width="800px"
               destroy-on-close>
      <div class="applyForm">
        <h3>I want to apply for Chinese University</h3>
        <div>

          <el-form ref="form"
                   :model="applyForm"
                   :rules="rules"
                   label-position='top'>
            <el-form-item label="Proposed start term"
                          prop="start_date">
              <el-radio-group v-model="applyForm.start_date">
                <el-radio label="2022, Autumn Semester">2022, Autumn Semester</el-radio>
                <el-radio label="2022, Spring Semester">2022, Spring Semester</el-radio>
                <el-radio :label="stInput == '' ? 'other':stInput">
                  Other <el-input v-model="stInput"></el-input>
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="Proposed subject to study"
                          prop="subject">
              <el-radio-group v-model="applyForm.subject">
                <el-radio label="Law & Philosophy">Law & Philosophy</el-radio>
                <el-radio label="Educationr">Educationr</el-radio>
                <el-radio label="Chinese Language & Culture">Chinese Language & Culture</el-radio>
                <el-radio label="Science & Engineering">Science & Engineering</el-radio>
                <el-radio label="Economics & Management">Economics & Management</el-radio>
                <el-radio label="Literature & Art">Literature & Art</el-radio>
                <el-radio label="Agriculture & Forestry">Agriculture & Forestry</el-radio>
                <el-radio label="Medicine">Medicine</el-radio>
                <el-radio :label="suInput == '' ? 'other':suInput">
                  Other <el-input v-model="suInput"></el-input>
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="Proposed Program">
              <el-input v-model="applyForm.program"></el-input>
            </el-form-item>
            <el-form-item label="Intend to apply for Scholarship?">
              <el-radio-group v-model="applyForm.isScholarship">
                <el-radio label="Yes">Yes</el-radio>
                <el-radio label="No">No</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="Proposed start term">
              <el-input v-model="applyForm.name"></el-input>
            </el-form-item>

            <el-form-item label="Preference of University Location">
              <el-radio-group v-model="applyForm.university_location">
                <el-radio label="No preference">No preference</el-radio>
                <el-radio label="Beijing">Beijing</el-radio>
                <el-radio label="Shanghai">Shanghai</el-radio>
                <el-radio label="Wuhan">Wuhan</el-radio>
                <el-radio :label="unInput == '' ? 'other':unInput">
                  Other <el-input v-model="unInput"></el-input>
                </el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="Proposed degree of the program">
              <el-radio-group v-model="applyForm.degree">
                <el-radio label="Bachelor Degree Program">Bachelor Degree Program</el-radio>
                <el-radio label="Master Degree Program">Master Degree Program</el-radio>
                <el-radio label="Ph.D Program">Ph.D Program</el-radio>
                <el-radio label="Non-degree Program">Non-degree Program</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="Most Advanced Education Background	">
              <el-radio-group v-model="applyForm.education_background">
                <el-radio label="High School">High School</el-radio>
                <el-radio label="Bachelor">Bachelor</el-radio>
                <el-radio label="Master">Master</el-radio>
                <el-radio label="Doctor">Doctor</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="Chinese Language Proficiency">
              <el-radio-group v-model="applyForm.chinese_language_proficiency">
                <el-radio label="None">None</el-radio>
                <el-radio label="A little">A little</el-radio>
                <el-radio label="Fair">Fair</el-radio>
                <el-radio label="Good">Good</el-radio>
                <el-radio label="Excellent">Excellent</el-radio>
                <div>
                  <el-radio :label="clInput == '' ? 'other':clInput">
                    Please enter the result if you got the HSK certificate <el-input style="width:200px"
                              v-model="clInput"></el-input>
                  </el-radio>
                </div>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="Language Proficiency">
              <el-radio-group v-model="applyForm.english_language_proficiency">
                <el-radio label="Native">Native</el-radio>
                <el-radio label="Excellent ">Excellent </el-radio>
                <el-radio label="Fair">Fair</el-radio>
                <el-radio label="Poor">Poor</el-radio>
                <el-radio label="None">None</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="Preference of Teaching Language">
              <el-radio-group v-model="applyForm.teaching_language">
                <el-radio label="Native">Native</el-radio>
                <el-radio label="Excellent ">Excellent </el-radio>
                <el-radio label="Fair">Fair</el-radio>
                <el-radio label="Poor">Poor</el-radio>
                <el-radio label="None">None</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="English Language Proficiency">
              <el-radio-group v-model="applyForm.language_proficiency">
                <el-radio label="Chinese">Chinese</el-radio>
                <el-radio label="English ">English </el-radio>
                <el-radio label="Either Chinese or English is OK">Either Chinese or English is OK</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="Current Location">
              <el-radio-group v-model="applyForm.current_location">
                <el-radio label="In China">In China</el-radio>
                <el-radio label="Outside China">Outside China</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="Have you studies in China?">
              <el-radio-group v-model="applyForm.is_studies_in_china">
                <el-radio label="Yes">Yes</el-radio>
                <el-radio label="No">No</el-radio>
              </el-radio-group>
            </el-form-item>
            <h3>Complete my personal information</h3>
            <el-form-item label="Gender"
                          prop="gender">
              <el-radio-group v-model="applyForm.gender">
                <el-radio label="Female">Female</el-radio>
                <el-radio label="Male">Male</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="Full name "
                          prop="full_name">
              <el-input v-model="applyForm.full_name"></el-input>
            </el-form-item>
            <el-form-item label="Country or Region"
                          prop="country_or_region">
              <el-select v-model="applyForm.country_or_region"
                         filterable
                         placeholder="please select your zone">
                <el-option v-for="(item,index) in CountryOrRegionInfo"
                           :key="index"
                           :label="item.value"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Email "
                          prop="email">
              <el-input v-model="applyForm.email"></el-input>
            </el-form-item>
            <el-form-item label="Tel "
                          prop="tel">
              <el-input v-model="applyForm.tel"></el-input>
            </el-form-item>
            <el-form-item label="Mobile "
                          prop="mobile">
              <el-input v-model="applyForm.mobile"></el-input>
              <p>Please enter it in full. Eg. 0086 27 87446076</p>
            </el-form-item>
            <el-form-item>
              <el-button type="primary"
                         @click="onSubmit('form')">Submit</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import axiosApi from "../api/api.js";
import { onBeforeMount, ref } from "vue";
import { ElMessage } from 'element-plus'

export default {
  name: 'Xuexiao',
  data () {
    return {
      faq: [{
        ft: 'How to find out my ideal program?',
        fa: ''
      },
      {
        ft: "Can China's universities provide programs taught in English?",
        fa: ''
      },
      {
        ft: 'Can I apply for the university directly?',
        fa: ''
      },
      {
        ft: 'Can I apply through an agency?',
        fa: ''
      },
      {
        ft: 'What help can I get from ZHUXUE?',
        fa: ''
      },
      {
        ft: "Is there entrance examination for bachelor programs in China's universities?",
        fa: ''
      },
      {
        ft: 'Can students with O-LEVEL or IGCSE apply for Bachelor programs in China?',
        fa: ''
      }],
      faq2: [{
        ft: 'Why choose online application?',
        fa: ''
      },
      {
        ft: 'When to apply?',
        fa: ''
      },
      {
        ft: 'What are the steps to complete online application? How to start?',
        fa: ''
      }],
      faq3: [{
        ft: 'What fees should I pay during application?',
        fa: ''
      },
      {
        ft: 'What are the payment methods?',
        fa: ''
      },
      {
        ft: 'Can I pay the application fee after I get admitted?',
        fa: ''
      },
      {
        ft: 'Is application fee refundable?',
        fa: ''
      },
      {
        ft: 'Why we being free of charge now?',
        fa: ''
      }],
      faq4: [{
        ft: 'After submitting my application files, how can I get the response from the university?',
        fa: ''
      },
      {
        ft: 'How to track my application progress?',
        fa: ''
      },
      {
        ft: 'What should I do if the program is canceled by the university after I submit my application?',
        fa: ''
      },
      {
        ft: 'How long does it cost for the university to deal with my application?',
        fa: ''
      },
      {
        ft: 'What should I do next if my application is accepted?',
        fa: ''
      },
      {
        ft: 'What should I do next if my application is rejected?',
        fa: ''
      }],
      dialogVisible: false,
      applyForm: {
        start_date: '',
        subject: '',
        program: '',
        isScholarship: '',
        university_location: '',
        degree: '',
        education_background: '',
        chinese_language_proficiency: '',
        english_language_proficiency: '',
        teaching_language: '',
        language_proficiency: '',
        current_location: '',
        is_studies_in_china: '',
        gender: '',
        full_name: '',
        country_or_region: '',
        email: '',
        tel: '',
        mobile: ''
      },
      rules: {
        gender: [
          {
            required: true,
            message: 'Please select gender',
            trigger: 'blur',
          },
        ],
        full_name: [
          {
            required: true,
            message: 'Please input Full Name',
            trigger: 'blur',
          },
        ],
        country_or_region: [
          {
            required: true,
            message: 'Please select country or region',
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: 'Please input email',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Please input correct email address',
            trigger: ['blur', 'change'],
          },
        ],
        tel: [
          {
            required: true,
            message: 'Please input tel',
            trigger: 'blur',
          },
        ],
        mobile: [
          {
            required: true,
            message: 'Please input mobile',
            trigger: 'blur',
          },
        ],
      },
      navRow: 0,
    }
  },
  setup () {
    const CountryOrRegionInfo = ref([])
    onBeforeMount(() => {
      axiosApi('CountryOrRegion', {}, 'get').then(res => {
        CountryOrRegionInfo.value = res.data
      })
    })
    return {
      CountryOrRegionInfo
    }
  },
  methods: {
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axiosApi('message', {
            ...this.applyForm
          }, 'post').then(res => {
            if (res.data.status) {
              ElMessage.success('success')
              this.dialogVisible = false
              this.$refs[formName].resetFields()
            } else {
              ElMessage.error('error')
            }
          })
        } else {
          return false
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/banner.png) center center no-repeat;
  height: 300px;
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.bzt {
  text-align: center;
  color: red;
  font-size: 26px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}
._txt {
  font-size: 14px;
  text-indent: 2em;
  line-height: 2;
}

.tit {
  border-left: 8px solid #0c3879;
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;
  line-height: 1;
}
.Guize {
  p {
    font-size: 16px;
    line-height: 2;
    margin-bottom: 10px;
    &.b {
      text-indent: 0;
      font-weight: bold;
      margin-top: 15px;
    }
  }
  .buzhou {
    h4 {
      margin: 0;
      font-size: 16px;
    }
    a {
      color: #0c3879;
    }
    p {
      text-indent: 0;
      font-size: 14px;
      color: #454545;
    }
    & ::v-deep {
      .el-timeline-item__timestamp.is-top {
        color: #0c3879;
        font-size: 20px;
        padding-top: 0;
        margin-bottom: 20px;
        font-weight: bold;
      }
    }
  }
  .faq {
    display: none;
    &.on {
      display: block;
    }
    .faqtit {
      display: flex;
      color: #4d73be;
      font-weight: normal;
      .imgbox {
        width: 30px;
        margin-right: 10px;
      }
    }
    .asCont {
      display: flex;
      .imgbox {
        width: 30px;
      }
      .txt {
        padding-left: 10px;
        line-height: 30px;
      }
    }
  }
}
._tit {
  color: #ff7e00;
  font-weight: bold;
}
._titp {
  font-size: 14px !important;
  color: #545454;
  line-height: 1.5;
  margin: 0 !important;
}
.list {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .lis {
    width: 11%;
  }
  .imgbox {
    width: 80%;
    margin: auto;
  }
  p {
    text-align: center;
    margin-top: 10px;
    font-size: 12px;
  }
}
.retit {
  margin: 20px 0;
  font-weight: bold;
  color: #0c3879;
}
.oth {
  text-align: center;
  ._globalBtn {
    margin-top: 10px;
    cursor: pointer;
  }
}
._rowNav {
  display: flex;
  border-bottom: 1px solid #dedede;
  p {
    margin: 0;
    width: 200px;
    margin-right: -1px;
    text-align: center;
    border: 1px solid #dedede;
    border-bottom: none;
    cursor: pointer;
  }
  p.on {
    color: #fff;
    background-color: #0c3879;
  }
}
</style>